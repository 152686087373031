import { createGlobalStyle } from 'styled-components'
// TODO : CHANGE FONT FACE
const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.background};

  @font-face {
    font-family: 'deffont';
    src: url(/fonts/arkitechmedium.ttf);
  }

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
