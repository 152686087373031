import { ChainId } from '@edxa/sdk'
import MULTICALL_ABI from './abi.json'

// TODO : CHANGE MULTICALL CONTRACT HERE
const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xbdCd8aD86C713EB3CfA9dD3c92780a3dE0da4D60',
  [ChainId.BSCTESTNET]: '0x919B2bDc5f93cc5b47F379813873443242F2ED61'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
